import { Navigate, useRoutes } from "react-router-dom";

import Login from "../Login";
import Accounts from "../pages/Account";
import StripeCancel from "../pages/StripeCancel";
import StripeSuccess from "../pages/StripeSuccess";
import Pricings from "../Pricings";

const Router = () => {
  const user = localStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}.LastAuthUser`
  );
  console.log(user);
  const routes = useRoutes([
    {
      path: "/login",
      element: !user ? <Login /> : <Navigate to="/" />,
    },
    {
      path: "/pricings",
      element: <Pricings />,
    },
    {
      path: "/",
      element: <Navigate to="/pricings" />,
    },
    {
      path: "/stripe/success",
      element: user ? <StripeSuccess /> : <Navigate to="/login" />,
    },
    {
      path: "/stripe/cancel",
      element: user ? <StripeCancel /> : <Navigate to="/login" />,
    },
    {
      path: "/accounts",
      element: user ? <Accounts /> : <Navigate to="/login" />,
    },
  ]);
  return routes;
};

export default Router;
