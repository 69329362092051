import axios from "axios";

const getStripeProducts = () => axios.get("/get-products");

const createCustomer = (email) =>
  axios.post("/create-customer", {
    email,
  });

const createSubscription = (priceId) =>
  axios.post("/create-subscription", {
    priceId,
  });

const subscriptionStatus = () => axios.get("/subscription-status");

const getSubscriptions = () => axios.get("/get-subscription");

const getProductById = (productId) => axios.post("/get-product", { productId });

const manageSubscription = () => axios.get("/customer-portal");

export {
  getStripeProducts,
  createCustomer,
  createSubscription,
  subscriptionStatus,
  getSubscriptions,
  getProductById,
  manageSubscription,
};
