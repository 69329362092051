import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const StripeSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => navigate("/"), 2000);
    return () => clearTimeout(timerId);
  });

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <svg
        className="w-16 h-16 text-green-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
      <h2 className="mt-4 text-2xl font-bold text-gray-800">Success!</h2>
    </div>
  );
};

export default StripeSuccess;
