const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_SIGN_IN_URL,
      redirectSignOut: process.env.REACT_APP_SIGN_OUT_URL,
      clientId: process.env.REACT_APP_CLIENT_ID,
      responseType: "code",
    },
  },
};
export default awsConfig;
