import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const StripeCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate("/pricings");
    }, 1000);

    return () => clearTimeout(timerId);
  });
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <svg
        className="w-16 h-16 text-red-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      <h2 className="mt-4 text-2xl font-bold text-gray-800">
        Oops, something went wrong.
      </h2>
      <p className="mt-2 text-gray-600">Session Cancelled.</p>
    </div>
  );
};

export default StripeCancel;
