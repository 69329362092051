import { NavLink } from "react-router-dom";

const DisabledNavLink = ({ to, disabled, ...rest }) => {
  if (disabled) {
    return <span {...rest}>{rest.children}</span>;
  } else {
    return <NavLink to={to} {...rest} />;
  }
};

export default DisabledNavLink;
