import { Auth } from "aws-amplify";
import { useContext } from "react";
import { LoadingContext } from "./context/loading";

const Login = () => {
  const [globalLoading, setGlobalLoading] = useContext(LoadingContext);
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="mt-6">
            <div className="mt-6 h-12 w-full">
              <div>
                <button
                  onClick={() => {
                    setGlobalLoading(true);
                    Auth.federatedSignIn({ provider: "Google" });
                    setGlobalLoading(false);
                  }}
                  disabled={globalLoading}
                  className="inline-flex w-full justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Google</span>
                  <img
                    src="google.svg"
                    alt="Sign in with Google"
                    className="h-12 w-auto"
                  />
                  <p>Sign in with Google</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
