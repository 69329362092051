import React from "react";
import { useState, useEffect, createContext } from "react";
import axios from "axios";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsConfig from "../aws-exports";
import { getDataFromLocalStorage } from "../utils/localStorage";

const UserContext = createContext();
Amplify.configure(awsConfig);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then(async (userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
      }
    });
    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  // axios config
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  axios.defaults.headers.common["Authorization"] = getDataFromLocalStorage(
    user?.pool?.clientId,
    user?.username,
    "accessToken"
  );

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
