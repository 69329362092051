import { Auth } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { Fragment, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { UserContext } from "./context/user";

import { urls } from "./constants/urls";
import { LoadingContext } from "./context/loading";
import DisabledNavLink from "./components/DisabledNavlink";

export default function Header() {
  const [user] = useContext(UserContext);
  const [globalLoading, setGlobalLoading] = useContext(LoadingContext);

  const handleClick = () =>
    (window.location.href = "https://www.sumantive.com/");

  return (
    <Popover className={`relative bg-[#282f52]`}>
      <div className="mx-auto max-w-7xl px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <button onClick={handleClick}>
              <span className="sr-only">Sumantive</span>
              <img
                className="xl:h-16 lg:h-16 md:h-16 h-8 w-auto"
                src="/sumative-logo.png"
                alt="Sumantive"
              />
            </button>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-900">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <NavLink
              to={urls.pricings}
              className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200"
            >
              Pricing
            </NavLink>
            {user ? (
              <NavLink
                to={urls.account}
                className="ml-8 whitespace-nowrap text-base font-medium text-white hover:text-gray-200"
              >
                Account
              </NavLink>
            ) : null}
            {user ? (
              <button
                disabled={globalLoading}
                onClick={() => {
                  setGlobalLoading(true);
                  Auth.signOut();
                  setGlobalLoading(false);
                }}
                className="ml-8 whitespace-nowrap text-base font-medium text-white hover:text-gray-200"
              >
                Logout
              </button>
            ) : (
              <DisabledNavLink
                className="ml-8 whitespace-nowrap text-base font-medium text-white hover:text-gray-200"
                to={urls.login}
                title="Login"
                disabled={globalLoading}
              >
                Login
              </DisabledNavLink>
            )}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform transition md:hidden"
        >
          <div
            className={`rounded-lg bg-[#282f52] shadow-lg ring-1 ring-black ring-opacity-5`}
          >
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <button onClick={handleClick}>
                  <img
                    className="xl:h-16 lg:h-16 md:h-16 h-8 w-auto"
                    src="/sumative-logo.png"
                    alt="Sumantive"
                  />
                </button>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <NavLink
                  to={urls.pricings}
                  className="text-base font-medium text-white hover:text-gray-200"
                >
                  Pricing
                </NavLink>
              </div>
              {user ? (
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <NavLink
                    to={urls.account}
                    className="text-base font-medium text-white hover:text-gray-200"
                  >
                    Account
                  </NavLink>
                </div>
              ) : (
                user
              )}
              <div>
                {user ? (
                  <button
                    disabled={globalLoading}
                    onClick={() => {
                      setGlobalLoading(true);
                      Auth.signOut();
                      setGlobalLoading(false);
                    }}
                    className="text-base font-medium text-white hover:text-gray-200"
                  >
                    Sign out
                  </button>
                ) : (
                  <DisabledNavLink
                    to={urls.login}
                    className="text-base font-medium text-white hover:text-gray-200"
                    disabled={globalLoading}
                    title={"Login"}
                  >
                    Login
                  </DisabledNavLink>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
