import { useState, useEffect, createContext, useContext } from "react";
import { getSubscriptions } from "../services/aws";
import { UserContext } from "./user";

const SubscriptionContext = createContext();

const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);
  const [user] = useContext(UserContext);
  useEffect(() => {
    if (user) getUserSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getUserSubscriptions = async () => {
    try {
      const { data } = await getSubscriptions();
      if (data.length) setSubscription([...data]);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <SubscriptionContext.Provider value={[subscription, setSubscription]}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionProvider };
// when a user first comes back from a checkout session, the button remains at buy plan and not manage subscription
