import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "./context/user";
import {
  createSubscription,
  getStripeProducts,
  manageSubscription,
} from "./services/aws";
import { currencies } from "./constants/currencies";
import { intervals } from "./constants/intervals";
import { urls } from "./constants/urls";
import Loader from "./components/Loader";
import { LoadingContext } from "./context/loading";
import { SubscriptionContext } from "./context/subscriptions";

const Pricings = () => {
  const [products, setProducts] = useState([]);
  const [user] = useContext(UserContext);
  const [loading, setLoading] = useState([0, false]);
  const [productLoading, setProductLoading] = useState(false);
  const [subscription] = useContext(SubscriptionContext);
  const [globalLoading, setGlobalLoading] = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setProductLoading(true);
      setLoading([-1, true]);
      const { data } = await getStripeProducts();
      setProducts(data);
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
      setLoading([0, false]);
    }
  };

  const handleSubscription = async (priceId, index) => {
    setLoading([index, true]);
    setGlobalLoading(true);
    if (!user) {
      setGlobalLoading(false);
      return navigate(urls.login);
    } else {
      try {
        const { data } = await createSubscription(priceId);
        setLoading((0, false));
        setGlobalLoading(false);
        window.location = data;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const manageSubscriptions = async (index) => {
    try {
      setLoading([index, true]);
      const { data } = await manageSubscription();
      setLoading([0, false]);
      window.location = data;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="bg-white min-h-full">
      <div className="bg-gradient-to-b from-white to-gray-50">
        <div className="mx-auto max-w-7xl px-6 pt-24 lg:px-8">
          <div className="sm:align-center sm:flex sm:flex-col">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-center">
              Pricing Plans
            </h1>
          </div>
          {productLoading ? (
            <Loader className="flex justify-center items-center h-48" />
          ) : (
            <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2">
              {products &&
                products.map((product, index) => {
                  return product.active ? (
                    <div
                      key={product.id}
                      className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm"
                    >
                      <div className="p-6 h-full">
                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          {product.name}
                        </h2>
                        <p className="mt-4 text-sm text-gray-500 h-20">
                          {product.description}
                        </p>
                        <div className="h-20">
                          <p className="mt-8">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                              $
                              {parseFloat(
                                product.default_price?.unit_amount / 100
                              ).toFixed(2)}
                            </span>{" "}
                            <span className="text-base font-medium text-gray-500">
                              {currencies[product?.default_price?.currency]} /
                              {
                                intervals[
                                  product?.default_price?.recurring.interval
                                ]
                              }
                            </span>
                          </p>
                        </div>

                        {subscription?.length ? (
                          <button
                            onClick={(e) => manageSubscriptions(index)}
                            className={`flex disabled:bg-indigo-400 w-full items-center justify-center rounded-md border border-transparent bg-[#282f52] py-2 px-4 text-base font-medium text-white`}
                            disabled={loading[1] || globalLoading}
                          >
                            {loading[1] && index === loading[0] ? (
                              <Loader button={true} />
                            ) : (
                              "Manage Subscription"
                            )}
                          </button>
                        ) : (
                          <button
                            onClick={(e) =>
                              handleSubscription(
                                product?.default_price.id,
                                index
                              )
                            }
                            className={`flex disabled:bg-indigo-400 w-full items-center justify-center rounded-md border border-transparent bg-[#282f52] py-2 px-4 text-base font-medium text-white`}
                            disabled={loading[1] || globalLoading}
                          >
                            {loading[1] && index === loading[0] ? (
                              <Loader button={true} />
                            ) : (
                              "Buy Plan"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null;
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Pricings;
