import { useContext, useEffect, useState } from "react";
import { SubscriptionContext } from "../context/subscriptions";
import { UserContext } from "../context/user";
import { getProductById, manageSubscription } from "../services/aws";
import Loader from "../components/Loader";

const TOKEN_LIMIT = {
  standard: 1000000,
  commercial: 2500000,
};

export default function Accounts() {
  const [user] = useContext(UserContext);
  const [subscription] = useContext(SubscriptionContext);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (subscription?.length && !product?.name) getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  const getProduct = async () => {
    try {
      setLoading(true);
      const { data } = await getProductById(subscription[0].plan.product);
      setProduct(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const manageSubscriptions = async () => {
    try {
      setLoading(true);
      const { data } = await manageSubscription();
      setLoading(false);
      window.location = data;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="overflow-hidden mx-auto mt-4 bg-white shadow sm:rounded-lg max-w-4xl">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Accounts
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Billing Information
        </p>
      </div>
      {loading ? (
        <Loader className="flex justify-center items-center h-48" />
      ) : (
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user?.attributes?.email}
              </dd>
            </div>
            {subscription?.length ? (
              <>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Current Plan
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {product?.name}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Token Limit
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {TOKEN_LIMIT[product?.name.toLowerCase()]}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Next Billing Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {subscription && subscription[0]?.current_period_end
                      ? new Date(
                          subscription[0]?.current_period_end * 1000
                        ).toLocaleDateString()
                      : "N/A"}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Manage</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <button
                      onClick={manageSubscriptions}
                      className={`text-[#282f52] mr-4 font-extrabold`}
                    >
                      Manage Subscription
                    </button>
                  </dd>
                </div>
              </>
            ) : null}
          </dl>
        </div>
      )}
    </div>
  );
}
