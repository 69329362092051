import { useState } from "react";

import Header from "./Header";
import Router from "./routes/appRoutes";

import { LoadingContext } from "./context/loading";

function App() {
  const [globalLoading, setGlobalLoading] = useState(null);

  return (
    <LoadingContext.Provider value={[globalLoading, setGlobalLoading]}>
      <Header />
      <Router />
    </LoadingContext.Provider>
  );
}

export default App;
